import { DecorativeImage, Publication } from "@libry-content/types";
import { getPlatformUrl } from "./platform";
import { screenshotApiUrl } from "./screenshotService";

export const getBookListImages = (publications: Publication[]) =>
  (
    publications
      ?.map((publication) => publication?.image)
      ?.filter((image): image is DecorativeImage => image !== undefined) || []
  ).slice(0, 3);

const getBookListFanUrl = (publications: Publication[]) => {
  const images = getBookListImages(publications)
    .map((image) => image.asset._ref)
    .join(",");
  return images.length ? getPlatformUrl() + `/felles/bokvifte?bilder=${images}` : undefined;
};

export const getBookListScreenshotUrl = (publications: Publication[], isDraft: boolean = false) => {
  const targetUrl = getBookListFanUrl(publications);
  return targetUrl
    ? `${screenshotApiUrl}?url=${encodeURIComponent(targetUrl)}&area=0,100,600,314${isDraft && "&draft=true"}`
    : undefined;
};
